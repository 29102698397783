<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="my-card m-0">
                <b-table 
                striped
                small
                id="myTables"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="forms"
                :fields="fields"
                :tbody-tr-class="rowClass"
                @row-dblclicked="editForm"
                v-hotkey="{ 'F2': editForm }"
                @row-contextmenu="handleContextMenuEvent"
                @row-clicked="clicked"
                head-row-variant="secondary"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>
                <template #cell(from_time)="data">
                    <span>
                        {{ data.item.from_time + "-" + data.item.before_time }}
                    </span>
                </template>
                <template #cell(first_name)="data">
                        {{data.item.last_name ? data.item.last_name + ' ' + data.item.first_name + ' ' : ''}}{{data.item.patronymic ? data.item.patronymic : ''}}
                </template> 
                <template #cell(gender)="data">
                    {{ data.item.gender == 1 ? 'мужской' : 'женский' }}
                </template>
            </b-table>
                <vue-context ref="menu">
                    <li>
                        <b-link
                        class="d-flex align-items-center"
                        v-b-modal="$route.name + 'CreateModal'"
                        >
                        <feather-icon
                            icon="PlusIcon"
                            size="16"
                        />
                        <span class="ml-75">Добавить</span>
                        </b-link>
                        <b-link
                        class="d-flex align-items-center"
                        @click="editForm()"
                        v-b-modal.formModalEdit
                        >
                        <feather-icon
                            icon="EditIcon"
                            size="16"
                        />
                        <span class="ml-75">Изменить</span>
                        </b-link>
                        <b-link
                        class="d-flex align-items-center"
                        @click="getId()"
                        v-b-modal.formHandler
                        >
                        <feather-icon
                            icon="Edit2Icon"
                            size="16"
                        />
                        <span class="ml-75">Обработка анкеты</span>
                        </b-link>
                        <b-link
                        class="d-flex align-items-center"
                        v-b-modal.mailModal
                        >
                        <feather-icon
                            icon="MailIcon"
                            size="16"
                        />
                        <span class="ml-75">Отправить смс</span>
                        </b-link>
                        <b-link
                        @click="call()"
                        class="d-flex align-items-center"
                        >
                        <feather-icon
                            icon="PhoneOutgoingIcon"
                            size="16"
                        />
                        <span class="ml-75">Позвонить</span>
                        </b-link>
                        <!-- <b-link
                        class="d-flex align-items-center"
                        >
                        <feather-icon
                            icon="BookmarkIcon"
                            size="16"
                        />
                        <span class="ml-75">История изменения</span>
                        </b-link> -->
                        <!-- <b-link
                        class="d-flex align-items-center"
                        >
                        <feather-icon
                            icon="InfoIcon"
                            size="16"
                        />
                        <span class="ml-75">Отправить на проверку</span>
                        </b-link> -->
                    </li>
                </vue-context>
                <b-modal id="mailModal" title="Отправить сообщение" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true">
                    <b-row>
                        <b-col cols="12">
                                <b-row>
                                <b-col cols="6">
                                    <label for="">Номер телефона</label>
                                    <b-input placeholder="Введите номер телефона" v-model="mail.phone"/>
                                </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" >
                                        <label for="">Сообщение:</label>
                                        <b-form-textarea placeholder="Введите текст..." v-model="mail.message"/>
                                    </b-col>
                                </b-row>
                            <b-row>
                                <b-col cols="2">
                                        <b-button  class="mt-2" variant="primary" @click="sendmessage()">Отправить</b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-modal>
        </b-card>
    </b-col>
    </b-row>
</div>
    
</template>
<script>
import VueContext from 'vue-context'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    props: ['forms'],
    components: {
        VueContext,
        ToastificationContent,
    },
    data(){
        return {
            fields: [
                { key: 'checkbox', label: '', thStyle: { width: '30px' } },
                { key: 'id', label: 'Id', sortable: true, thStyle: { width: '30px' } },
                { key: 'division', label: 'Подразделение', sortable: true, thStyle: { width: '150px' } },
                { key: 'first_name', label: 'Имя', sortable: true, thStyle: { width: '150px' } },
                { key: 'last_name', label: 'Фамилия', sortable: true, thStyle: { width: '150px' } },
                { key: 'full_name', label: 'ФИО', sortable: true, thStyle: { width: '250px' } },
                { key: 'date_of_birth', label: 'Дата рождения', sortable: true, thStyle: { width: '150px' } },
                { key: 'phone', label: 'Телефон', sortable: true, thStyle: { width: '150px' } },
                { key: 'car_number', label: 'Номер автомобиля', sortable: true, thStyle: { width: '150px' } },
                { key: 'car_model', label: 'Модель автомобиля', sortable: true, thStyle: { width: '150px' } },
                { key: 'car_color', label: 'Цвет автомобиля', sortable: true, thStyle: { width: '150px' } },
                { key: 'color', label: 'Цвет', sortable: true, thStyle: { width: '150px' } },
                { key: 'year', label: 'Год выпуска', sortable: true, thStyle: { width: '100px' } },
                { key: 'status', label: 'Статус', sortable: true, thStyle: { width: '150px' } },
                { key: 'gender', label: 'Пол', sortable: true, thStyle: { width: '100px' } },
                { key: 'serial_number_passport', label: 'Серия паспорта', sortable: true, thStyle: { width: '150px' } },
                { key: 'serials_number', label: 'Номер паспорта', sortable: true, thStyle: { width: '150px' } },
                { key: 'expirated_driver_license', label: 'Срок действия ВУ', sortable: true, thStyle: { width: '200px' } },
                { key: 'expirated_passport', label: 'Срок действия паспорта', sortable: true, thStyle: { width: '200px' } },
                { key: 'created_at', label: 'Создано', sortable: true, thStyle: { width: '200px' } },
                { key: 'updated_at', label: 'Обновлено', sortable: true, thStyle: { width: '200px' } },
                { key: 'created_user.full_name', label: 'Создатель', sortable: true, thStyle: { width: '200px' } },
                { key: 'dop_info', label: 'Доп. информация', sortable: true, thStyle: { width: '150px' } },
                { key: 'reminder_date_at', label: 'Дата напоминания', sortable: true, thStyle: { width: '200px' } },
                { key: 'reminder_user', label: 'Напомнил', sortable: true, thStyle: { width: '200px' } },
                { key: 'comment', label: 'Коментарий', sortable: true, thStyle: {width: '150px'}}
            ],
            mail: {
                phone: '',
                message: ''
            },
            id: ''
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        call(){
            if(this.$store.state.caller.status) {
                let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
                this.$http.post('/connect-driver-operator', {
                    number: initiatorNumber,
                    phone: this.mail.phone
                })
                .then(() => {
                    this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Запрос на соединение отправлен',
                        icon: 'XIcon',
                        variant: 'success',
                        text: "",
                        },
                    })
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: "Ошибка",
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err,
                        },
                    })
                })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
        },
        editForm(){
            this.$emit('editForm', this.id)
        },
        getId() {
            this.$emit('getId', this.id)
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            else if (item.status_id === 1) return 'table-warning'
            else if (item.status_id === 2) return 'table-success'
            else if (item.status_id === 1) return ''
            else return 'table-danger'
        },
        refresh(){
            this.$emit('refresh')
        },
        handleContextMenuEvent(item, index, event){
            event.preventDefault();
            this.$refs.menu.open(event)
            this.mail.phone = item.phone
            this.id = item.id
        },
        sendmessage(){
            this.$http
                .post('sms-send', this.mail)
                .then(res => {
                    this.$bvModal.hide('mailModal')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Сообщение отправлено!`,
                        },
                    })
                    this.$emit('refresh')
                    this.mail = {
                        phone: '',
                        message: '' 
                    }
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.textContent).trim()
            this.id = item.id
            cellSelect()
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
} 
</style> 