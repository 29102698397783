<template>
  <b-modal
    id="formHandler"
    title="Обработка анкеты"
    size="lg"
    hide-footer
    no-enforce-focus
    :no-close-on-backdrop="true"
    @show="getDataProfiles()"
    @shown="detectStartTime"
    @hidden="detectEndTime"
    @close="clearInterval(detectStartTime)"
  >
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between">
          <b-form-radio-group
            v-model="handleForm.status_id"
            :options="formStatuses"
            class="demo-inline-spacing mb-1"
            style="
              display: grid;
              grid-template-columns: 35% 42% 35%;
              grid-template-rows: 1fr 1fr;
            "
            value-field="id"
            text-field="name"
            @change="buttonDisabled(), selectedStatus()"
          />
        </div>
        <b-row class="align-items-end">
          <b-col cols="6">
            <b-button
              class="btn-p"
              variant="primary"
              @click="editForm()"
              v-b-modal.formModalEdit
            >
              Редактировать анкету
            </b-button>
          </b-col>
          <b-col cols="6">
            <label for="">Причина</label>
            <v-select
              v-model="handleForm.cause_id"
              :disabled="causes.length == 0"
              :options="causes"
              label="name"
              :reduce="(option) => option.id"
            />
          </b-col>
          <b-col cols="6">
            <b-button
              class="mt-1 btn-p"
              variant="primary"
              v-b-modal.driversCreateModal
              :disabled="condition"
              @click="sendDataDriver"
            >
              Добавить исполнителя
            </b-button>
          </b-col>
          <b-col cols="6">
            <label for="">Номер водителя</label>
            <b-input v-model="driverPhone" disabled />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <label for="">История</label>
            <b-textarea v-model="handleForm.dop_info" disabled></b-textarea>
          </b-col>
          <b-col cols="6">
            <label for="">Комментарий</label>
            <b-textarea v-model="handleForm.comment"></b-textarea>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <label for="">Откуда узнали</label>
            <v-select />
          </b-col>
          <b-col cols="6">
            <label for="">Дубль</label>
            <b-input
              v-model="handleForm.driver_id_duplicated"
              type="text"
              placeholder=""
            ></b-input>
          </b-col>
        </b-row>
        <b-col>
          <b-row class="mt-2 align-items-center">
            <b-col class="pl-0"
              >{{ dateTime.hours }}:{{
                dateTime.minutes < 10
                  ? "0" + dateTime.minutes
                  : dateTime.minutes
              }}</b-col
            >
            <b-col cols="3" class="mr-1 p-0">
              <b-button v-if="variant" variant="primary" @click="call()">
                Позвонить
              </b-button>
              <b-button v-else variant="danger" @click="hangup()">
                Отключить
              </b-button>
            </b-col>
            <b-button variant="primary" v-b-modal.mailModal class="mr-1">
              CМС
            </b-button>
            <b-button
              :disabled="!submitButtonState"
              class="mr-1"
              variant="primary"
              @click="patchData()"
            >
              OK
            </b-button>
            <b-button variant="danger" @click="closeModal()"> Отмена </b-button>
          </b-row>
        </b-col>
      </b-col>
      <div class="info-modal__interior">
        <b-row class="mb-1">
          <b-col class="info-modal__text" cols="2"
            >Салом, "Номи ронанд". Номи ман «Номи шумо». Ман намояндаи хадамоти
            фармоиши таксии «номи хадамот» мебошам. Шумо оиди ҳамкорӣ ба мо
            ариза гузошта будед. Айни ҳол Шумо бо мо сӯҳбат карда
            метавонед?</b-col
          >
        </b-row>
        <b-row class="info-modal__title"> Агар чавоб "Не" бошад: </b-row>
        <div class="info-modal__text">
          Кай ба Шумо занг зада метавонем? (вақти ёдрасонӣ нишон дода шавад)
        </div>
        <b-row class="info-modal__title"> Агар ҷавоб «Ҳа» бошад </b-row>
        <div class="info-modal__text">
          Хуб. Дар ин ҳолат ман ба Шумо якчанд савол медиҳам, оиди шартҳои кор
          накл мекунам, баъд агар Шумо ягон савол дошта бошед, чавоб медиҳам.
          Лутфан бигӯед...
        </div>
        <b-row class="info-modal__title">
          Дар бораи ҷои кори холӣ аз куҷо фаҳмидед?
        </b-row>
        <div class="info-modal__text">
          (агар ронанда ҷавоб диҳад: «Аз интернет», савол дода шавад. «Номи
          сомона ё шабакаи иҷтимоиро номбар кунед?»)
        </div>
        <div class="info-modal__text">
          (ҷавобро дар майдончаи «Аз куҷо фаҳмид» инъикос кунед) Автомашинаи
          шумо чи гуна аст? Соли бароришаш чанд аст?
        </div>
        <div class="info-modal__text">
          (ҷавоб дар эзоҳ инъикос карда шавад) Автомашинаи Шумо камбудиҳои
          намоёни техникӣ ё қисмҳои берунӣ дорад?
        </div>
        <div class="info-modal__text">
          (агар камбудиҳо дошта бошад дар эзоҳҳо инъикос карда шавад) - Шумо
          ягон бор дар такси кор кардаед?
        </div>
        <div class="info-modal__text">
          (Агар айни ҳол кор карда истода бошад, мавҷудияти реклама дар
          автомашинаро саҳеҳ кунед)
        </div>
        <b-row class="info-modal__title">
          Кутоҳ дар бораи шартҳои кор накл мекунам.
        </b-row>
        <div class="info-modal__text">
          Мо речаи ОЗОДИ корро пешниҳод менамоем; 90%-и даромад аз Шумост! Шумо
          ба мо ҲАМАГЙ 10% аз фармоиши ИЧРОШУДА пардохт менамоед!
        </div>
        <div class="info-modal__text">
          Фармоишҳо бо воситаи барнома ичро карда мешаванд, пайвастшавӣ ба
          интернет зарур аст.
        </div>
        <div class="info-modal__text">
          Шумо пешакӣ мизочро аз куҷо гирифтан, ба куҷо бурдан ва арзиши рохро
          мебинед.
        </div>
        <b-row class="info-modal__title"> Шумо ягон савол доред? </b-row><br />
        <div class="info-modal_text">
          Ба хадамот Шумо метавонед бо 2 рох пайваст шавед: (Агар офис вучуд
          надошта бошад ба сархати 2 гузаред)
        </div>
        <br />
        <div class="info-modal_text">
          Метавонед ба офис омада, дар ин ҷо ҳамроҳи менеҷерони мо барномаро
          насб намоед ва чӣ тавр бо он кор карданро онҳо ба Шумо мефаҳмонанд; 2.
          Ё худи ҳозир бақайдгириро бо телефон гузаред. Дар ин ҳол ба Шумо
          насбкунии мустақилонаи барнома ва гузаштани омӯзиши кор бо он лозим
          мешавад. Кадом роҳ ба Шумо кулай аст?
        </div>
        <br />
        <div class="info-modal_text">
          (давом додани мусоҳиба мувофики варианти интихобгардида)
        </div>
        <br />
        <b-row class="info-modal__title"> 1. Даъват ба офис </b-row>
        <div class="info-modal__text">
          Хуб, он гоҳ ба офиси мо биёед, хубтар мешуд дар автомашинаи тоза, бо
          худ шиноснома ва телефони мобилиро гиред. Офис дар суроғаи «Суроғаи
          офис» ҷойгир аст ва «Соати корӣ» (маълумот F1)
        </div>
        <div class="info-modal__text">
          Такрибан кай омада метавонед? (дар майдончаи «Санаи ёдраскунӣ» инъикос
          мекунем)
        </div>
        <div class="info-modal__text">
          Мо шуморо интизор ҳастем. (санаи таъингардидаро мегўем). Саломат
          бошед!
        </div>
        <b-row class="info-modal__title">
          2. Бақайдгирӣ тавассути телефон:
        </b-row>
        <div class="info-modal__text">
          Барои бақайдгирӣ ба ман маълумот дар бораи рақами давлатии автомашинаи
          Шумо ва силсила/рақами шаҳодатномаи ронандаги лозим аст
        </div>
        <div class="info-modal__text">
          (Маълумот нест вақти занги баъдинаро маслиҳат кардан)
        </div>
        <div class="info-modal__text">
          (Маълумот ҳаст корти автомашина ва ронанда тартиб дода шавад)
        </div>
        <div class="info-modal__text">
          Ба рақами телефони шумо смс-паём меояд, дар он ҳавола ба барнома,
          логин ва пароли шумо мавчуданд. Барномаро тариқи гузариш ба ҳавола
          насб намоед, пас логин ва паролро ворид намоед. Баъд аз он тугмаи
          "Едрас намудани рам"-ро пахш намоед. Пас аз ба барнома ворид гаштан,
          ба шумо давраи омӯзишро гузаштан пешниҳод карда мешавад. Омӯзиши
          интерактивӣ ду дақиқаро дарбар мегирад. Ҳамчунин дар қисмати
          "Дастгири" видеодарсҳо мавҷуданд.
        </div>
        <div class="info-modal__text">
          Ба шумо ҳисоби шахсӣ кушода шудааст, ки аз он фоизҳои комиссия барои
          фармоишҳои иҷрогардида гирифта мешаванд. Ба ҳисоби мазкур аллакай 7
          сомонии туҳфавӣ ворид карда шудааст. Бо бораи тартиби пуркунии ҳисоби
          шахси Шумо метавонед дар қисмати эълонҳо шинос шавед.
        </div>
        <b-row class="info-modal__title"> Фотоназорат: </b-row>
        <div class="info-modal__text">
          Дар давоми 7 рӯз ба Шумо гузаштани фотоназорат тавассути барнома лозим
          мешавад.
        </div>
        <div class="info-modal__text">
          Шумо ягон савол доред? Агар саволҳо набошад:
        </div>
        <div class="info-modal__text">
          Агар ягон савол дошта бошед, шумо дар қисми "Дастгирӣ"-и барнома ё дар
          хучраи шахсии ронанда муроҷиат гузошта метавонед.
        </div>
      </div>
    </b-row>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { GlobalEventEmitter } from "@/utils/GlobalEventEmitter.js";
const date = new Date();
export default {
  props: ["id"],
  components: {
    vSelect,
  },
  data() {
    return {
      formStatuses: [],
      dateTime: {
        hours: date.getHours(),
        minutes: date.getMinutes(),
      },
      formTime: {
        form_name: null,
        start_time: null,
        end_time: null,
      },
      condition: true,
      inputCondition: true,
      handleForm: {
        driver_profile: this.id,
        status_id: "",
        cause_id: "",
        comment: "",
      },
      variant: true,
      driversProfile: null,
      causes: [],
      driversProfileTrate: null,
      submitButtonState: true,
      driverPhone: "",
      dateTimeInterval: null,
    };
  },
  mounted() {
    this.dateTimeInterval = setInterval(this.setDateTime, 1000);
    this.$http
      .get("driver-profile-statuses")
      .then((res) => {
        this.formStatuses = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    call() {
      if (this.$store.state.caller.status) {
        let initiatorNumber = JSON.parse(
          localStorage.getItem("sipConfig")
        ).user;
        this.$http
          .post("/connect-driver-operator", {
            number: initiatorNumber,
            phone: this.driversProfile.phone,
          })
          .then(() => {
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", true);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Запрос на соединение отправлен",
                icon: "CheckIcon",
                variant: "success",
                text: "",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка",
                icon: "XIcon",
                variant: "danger",
                text: err,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Включите SIP телефонию",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      }
    },
    detectEndTime() {
      this.formTime.end_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      this.$http
        .post("time-form", this.formTime)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detectStartTime() {
      this.formTime.form_name = "requestHandleForm";
      this.formTime.start_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
    },
    selectedStatus() {
      if (this.handleForm.status_id) {
        this.$http
          .post("driver-profiles/treatment/get-cause-by-status", {
            status_id: this.handleForm.status_id,
          })
          .then((res) => {
            this.causes = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    patchData() {
      this.submitButtonState = false;
      if (this.causes.length === 0) {
        this.handleForm.cause_id = undefined;
      }
      this.$http
        .patch(`driver-profiles/treatment/${this.id}`, this.handleForm)
        .then((res) => {
          this.closeModal();
          this.submitButtonState = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success",
              text: "",
            },
          });
        })
        .catch((err) => {
          this.submitButtonState = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: JSON.stringify(err.response.data.errors),
              icon: "XIcon",
              variant: "danger",
              text: "",
            },
          });
        });
    },
    hangup() {
      GlobalEventEmitter.$emit("hangUpCall");
      this.variant = true;
    },
    setDateTime() {
      const date = new Date();
      this.dateTime = {
        hours: date.getHours(),
        minutes: date.getMinutes(),
      };
    },
    getDataProfiles() {
      this.$http
        .get(`driver-profiles/${this.id}/edit`)
        .then((res) => {
          this.driversProfile = res.data;
          this.driverPhone = res.data.phone;
        })
        .catch((err) => {
          console.log(err);
        });
      this.$http
        .get(`/driver-profiles/treatment/${this.id}/edit`)
        .then((res) => {
          this.handleForm = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buttonDisabled() {
      if (this.handleForm.status_id != 2 && this.handleForm.status_id != 4) {
        this.condition = true;
      } else {
        this.condition = false;
      }
      if (this.handleForm.status_id == 1 || this.handleForm.status_id == 3) {
        this.inputCondition = false;
      } else {
        this.inputCondition = true;
      }
    },
    closeModal() {
      this.$bvModal.hide("formHandler");
      this.handleForm = {
        status_id: "",
        dop_info: "",
        comment: "",
        id: this.id,
      };
    },
    editForm() {
      this.$emit("editForm", this.id);
    },
    sendDataDriver() {
      this.$emit("sendDataDriver", this.driversProfile);
    },
  },
  created() {
    // adds the event listener function that will handle the event
    GlobalEventEmitter.$on("confirmedCall", () => {
      this.variant = false;
    });
  },
  destroy() {
    // removes event listener
    GlobalEventEmitter.$off("confirmedCall");
  },
};
</script>

<style scoped>
.btn-p {
  width: 100%;
}
.info-modal__interior {
  position: fixed;
  z-index: 1000;
  top: 23px;
  left: 0px;
  /* max-width: 300px; */
  width: 300px;
  max-height: 565px;
  background: rgb(255, 253, 253);
  padding: 19.6px;
  border-radius: 4px;
  overflow-y: scroll;
}

.info-modal__tariff {
  margin-top: 5px;
  font-weight: bold;
}

.info-modal__title {
  margin: 8px 0 8px 0;
  font-weight: bold;
  margin: 0 auto;
  min-width: 100%;
}
.info-modal__text {
  margin: 0 auto;
  margin: 8px 0 8px 0;
  min-width: 100%;
}
.info-modal__allowances {
  margin-top: 10px;
  line-height: 10px;
}

.order-content {
  margin-left: 30%;
}

@media (min-width: 1780px) {
  .info-modal__interior {
    left: 4%;
  }
  .order-content {
    margin-left: 25%;
  }
}
@media (min-width: 1580px) {
  .info-modal__interior {
    margin-left: 4%;
  }
  .order-content {
    margin-left: 15%;
  }
}
@media (max-width: 1580px) {
  .info-modal__interior {
    margin-left: 0%;
  }
  .order-content {
    margin-left: 200px;
  }
}
@media (max-width: 1450px) {
  .order-content {
    max-width: 920px;
  }
}
@media (max-width: 1359px) {
  .order-content {
    margin-left: inherit;
  }
}

@media (min-width: 1900px) {
  .info-modal__interior {
    max-width: 500px;
  }
}

@media (min-width: 1780px) {
  .info-modal__interior {
    left: 4%;
  }
}

@media (min-width: 1580px) {
  .info-modal__interior {
    margin-left: 4%;
  }
}

@media (max-width: 1580px) {
  .info-modal__interior {
    margin-left: 0%;
  }
}
</style>
